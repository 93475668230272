@import "../node_modules/prismjs/themes/prism-tomorrow";
@import "@core/scss/react/libs/tables/react-dataTable-component.scss";

.swal2-actions {
  gap: "20px";
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}
.ct-responsive-datatable {
  .react-dataTable {
    .rdt_TableBody {
      .rdt_TableRow {
        .rdt_TableCell {
          &:nth-child(n + 1) {
            @media (max-width: 575px) {
              display: none;
            }
          }
          &:nth-child(1) {
            @media (max-width: 575px) {
              display: block;
            }
          }
        }
      }
    }
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          &:nth-child(n + 1) {
            @media (max-width: 575px) {
              display: none;
            }
          }
          &:nth-child(1) {
            @media (max-width: 575px) {
              display: block;
            }
          }
        }
      }
    }
  }
}
.text-size-15-cell-col {
  font-size: 15px;
  font-weight: 600;
}
@media screen and (min-width: 1200px)
{
  .add-misc-popup {
    max-width: 1800px;
  }
}