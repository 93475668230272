/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
::-moz-selection {
  /* Code for Firefox */
  color: #ffffff !important;
  background: #072f3d !important;
}

::selection {
  color: #ffffff !important;
  background: #072f3d !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  border: 0px none #ffffff;
}
.loginImg {
  background-image: url("../images/banners/login-banner.jpg");
  // background-image: linear-gradient( rgb(0 0 0 / 48%), rgb(0 0 0 / 43%) ), url('../images/banners/login-banner.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.sign-in-warpper .auth-inner {
  max-width: 600px !important;
}
.fw-600 {
  font-weight: 600;
}
// .auth-register-form label{
// 	display: none;

// }
.app-calendar .app-calendar-sidebar {
  right: calc(-18rem - 1.2rem);
  border-left: 1px solid #ebe9f1;
}
.modal .modal-content {
  box-shadow: 0 5px 20px 0 rgb(34 41 47 / 10%) !important;
}
.form-group {
  margin-bottom: 12px;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: auto;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[dir="ltr"] legend {
  float: inherit;
}
.form-label {
  font-weight: 600;
}
label span {
  cursor: pointer;
}
.event-list-buttons {
  list-style: none;
  padding: 0;
}
.event-list-buttons li {
  background: #f8f8f8;
  padding: 4px 16px;
  color: black;
  border-radius: 4px;
  border: 1px solid;
  margin-bottom: 6px;
  font-weight: 500;
  cursor: pointer;
}
.list-style-none {
  list-style: none;
}
.fw-500 {
  font-weight: 500;
}
.fs-14 {
  font-size: 14px;
}
// .fc-daygrid-day-frame::after{
// 	content: '+';
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	z-index: 2;
// }
.mb-n3 {
  position: absolute;
  right: 0;
  top: 30px;
}
.p-relative {
  position: relative;
}
.input-icon {
  width: calc(100% - 40px);
}
.auth-wrapper.auth-cover .brand-logo {
  position: unset !important;
  // top: 2rem;
  // z-index: 1;
  justify-content: center !important;
}
.menu-expanded .main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 170px;
  width: 100%;
  // margin-top: 8px;
}
.menu-collapsed .main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 120px;
  width: 100%;
  margin-top: 8px;
  // margin-top: 8px;
}
.menu-collapsed
  .main-menu.expanded
  .navbar-header
  .navbar-brand
  .brand-logo
  img {
  max-width: 170px;
  width: 100%;
  margin-top: 0px;
}
.navbar-brand {
  margin-right: 0.4rem;
}
.main-menu .navbar-header .navbar-brand .brand-text {
  font-size: 16px !important;
  padding-left: 0.5rem;
}
.main-menu .navbar-header .navbar-brand {
  margin-top: 0rem !important;
}
.nav .modern-nav-toggle {
  // padding: 0;
  margin: 1.2rem 0 !important;
}
.main-menu.menu-light .navigation > li.active > a {
  font-weight: 600 !important;
  font-size: 13px;
}
.main-menu.menu-light .navigation > li > a {
  margin: 0 10px;
  font-weight: 600 !important;
  font-size: 14px;
}
.main-menu.menu-light .navigation li a {
  padding: 10px 8px 10px 8px;
}
.main-menu.menu-light {
  background: #f4f4f0;
}
.navbar-light {
  background: #eae5dd;
}
.main-menu.menu-light .navigation {
  background: #f4f4f0;
}
@media screen and (max-width: 992px) {
  .auth-wrapper.auth-cover .brand-logo {
    display: flex;
    justify-content: center !important;
    // background-color: #072f3d;
    top: 0 !important;
  }
}
.modal .modal-header {
  background-color: #eae5dd;
  border-radius: 0;
}
.modal-title {
  // color: #072f3d;
  color: #ab4446;
  font-weight: 600;
}
.modal-content {
  border-left: 6px solid #ab4446;
}
.swal2-confirm,
.swal2-cancel {
  margin: 0 6px !important;
}

.fc-daygrid-dot-event .fc-event-title {
  overflow: unset !important;
  word-break: break-all;
  font-size: 10px;
}

.fc .fc-daygrid-event-harness .fc-event {
  display: flex;
  align-items: baseline;
}

.fc-daygrid-event {
  white-space: inherit !important;
}
.nav-item.active .custom-svg {
  fill: #ffffff;
}
.hover-link-cs:hover {
  color: #ab4446;
}
.form-control.StripeElement {
  height: 38px;
}
::placeholder {
  color: #b9b9c3 !important;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b9b9c3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b9b9c3;
}
.InputElement.is-empty {
  color: #b9b9c3 !important;
}
.swal2-styled.swal2-confirm {
  background-color: #072f3d !important;
  text-transform: capitalize !important;
}
.swal2-styled.swal2-confirm:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #072f3d !important;
}
.swal2-actions.swal2-confirm:not(.swal2-loading)
  .swal2-styled.swal2-confirm:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  box-shadow: 0 8px 25px -8px #072f3d !important;
}
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
  margin-top: 0rem !important;
}
.bs-stepper
  .bs-stepper-header
  .step
  .step-trigger
  .bs-stepper-label
  .bs-stepper-title {
  margin-bottom: 0px !important;
}
.bs-stepper .bs-stepper-header .line {
  margin-bottom: 2px !important;
}
.h-85-cs {
  height: 85px;
}

// .invalid-feedback{
// visibility: hidden;
// }
// .is-invalid + .invalid-feedback{
// 	visibility: visible;
// }
.nav-pills .nav-link {
  border: 1px solid #072f3d;
  border-top: 1px solid #072f3d;
  border-bottom: 1px solid #072f3d;
  // background: #eae5dd;
  border-radius: unset;
}
.nav-pills .nav-item:first-child .nav-link {
  border-left: 2px solid #072f3d;
}
.nav-pills .nav-item:last-child .nav-link {
  border-right: 2px solid #072f3d;
}
.kmbVPi {
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: unset !important;
  word-break: break-all;
}
.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
  // min-height: 0;
  height: unset !important;
}
// .css-1pahdxg-control:hover{
//   border-color: #072f3d !important;
// }
.css-t3ipsp-control:hover {
  border-color: #072f3d !important;
}
.css-t3ipsp-control {
  border-color: #072f3d !important;
  box-shadow: unset !important;
}
.form-control {
  padding: 0.571rem 8px;
}
.fc-h-event {
  border: 1px solid #1b2e3c !important;
  background-color: #1b2e3c !important;
  color: #fff !important;
}
.calendar-wrapper-cs .fc .fc-day-today {
  background: #e8e6de !important;
  background-color: #e8e6de !important;
}
.calendar-wrapper-cs
  .fc
  .fc-toolbar
  .fc-button-group
  .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active,
.calendar-wrapper-cs
  .fc
  .fc-toolbar
  .fc-button-group
  .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
  background-color: rgb(35 45 59) !important;
  border-color: #072f3d !important;
  color: #fff !important;
  font-weight: 500 !important;
}
.plan-freezer-prep-day-wrapper
  .react-dataTable
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell {
  height: auto !important;
}
.prep-dar-tool-btn {
  min-width: 200px;
  height: 30px;
}
.btn-print-cs-w {
  width: 105px;
}
.recipe-management-header-btn {
  text-align: center;
}
.recipe-management-header-btn button {
  width: 200px;
  text-align: center;
}

element.style {
  height: auto;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 180px;
}
@media (max-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
}
@media (max-width: 577px) {
  .prep-dar-tool-btn {
    min-width: 200px;
    width: 100%;
  }
  .auth-wrapper .auth-bg {
    margin: 0 !important;
  }
}
// @media (max-width: 475px) {
//   .recipe-management-header-btn{
//     text-align: center;
//   }
//   .recipe-management-header-btn button{
//     margin-left: 0 !important;
//   }

// }
@media (max-width: 380px) {
  .fc
    .fc-toolbar
    .fc-button-group
    .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
    background-color: transparent;
    border-color: #072f3d;
    font-size: 10px;
    padding: 6px 10px;
  }
}

.main-menu.menu-light .navigation li a {
  padding: 10px 8px 10px 6px;
}
.main-menu.menu-light .navigation > li > a {
  margin: 0 6px;
}
.main-menu.menu-light .navigation > li > a {
  font-size: 13px;
}
.rti--container input {
  width: 100%;
}
.rti--container:focus-within {
  border-color: #072f3d;
  box-shadow: unset !important;
}
body.modal-open,
body {
  padding-right: 0 !important;
}
.recipes-instruction-dis-type {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}
.recipes-instruction-dis-type-text {
  font-weight: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}
.dishes-heading-grocery-instruction {
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}
.dishes-main-heading-grocery-instruction {
  font-size: 18px;
  font-weight: 500;
}
.instruction-list span {
  font-size: 13px;
  margin-bottom: 6px;
  font-weight: 400;
  // padding-left: 16px;
  white-space: normal !important;
}
.dishes-grocery-instruction-wrapper {
  padding-left: 4%;
}
.instruction-list-mb li {
  margin-bottom: 16px !important;
}
.main-div .print-recipe-border-bottom {
  border-bottom: 2px dashed;
  padding-bottom: 32px;
}
.main-div .print-recipe-border-bottom:last-child {
  border-bottom: unset !important;
  padding-bottom: unset !important;
}
.text-underline {
  text-decoration: underline;
}

* {
  white-space: normal !important;
}
.date-picker-cs.rdp-form-control {
  border-radius: 0.357rem !important;
}
.cs-height-cell {
  min-height: 38px;
  height: 100%;
  display: flex;
  align-items: center;
}
.inventory-report-border-bottom {
  border-bottom: 1px dashed;

  padding: 14px 0px 22px 0px;
}
.inventory-report-border-bottom:last-child {
  border-bottom: unset !important;
}
.one-event-title {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px dashed;
  line-height: 15px;
}
.event-sides-list {
  padding-bottom: 8px;
  padding-left: 1rem;
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px dashed;
}
.event-sides-list li {
  font-size: 10px;
}
.event-checkbox-label.form-label {
  color: #ffffff;
  font-size: 10px;
  margin-right: 4px;
  margin-bottom: 0;
  margin-top: 2px;
}
.build-a-meal-radio {
  margin-top: 55px;
}
@media (max-width: 992.5px) {
  .legend-text-cs {
    font-size: 15px;
  }
}
@media (max-width: 767.5px) {
  .logo-border-d-none {
    border-right: unset !important;
    margin-bottom: 12px;
  }
  .build-a-meal-radio {
    margin-top: -12px;
  }
}
.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: auto !important;
}
.btn-darker-tan {
  background-color: #d2b48c !important;
  border-color: #d2b48c !important;
  color: var(--bs-dark) !important;
}
.add-meal-calendar-label {
  font-size: 1rem;
}
@media (max-width: 577px) {
  [dir="ltr"] .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  [dir="ltr"] .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 0px;
  }
  [dir="ltr"] .fc-direction-ltr .fc-daygrid-event.fc-event-start,
  [dir="ltr"] .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 0px;
  }
  .fc .fc-daygrid-event-harness .fc-event {
    font-size: 0.8rem;
    font-weight: 500;
  }
  [dir] .fc .fc-daygrid-event-harness .fc-event {
    padding: 0.25rem 0.1rem;
  }
  .fc-event-title.fc-sticky {
    overflow: unset !important;
    white-space: unset !important;
    text-overflow: unset !important;
    word-break: break-all;
    font-size: 7px;
  }
}
.fc-list-event-time {
  display: none !important;
}
.menu-collapsed .expanded .menu-item.text-truncate {
  animation-duration: 2s;
  // animation-iteration-count: e;
  opacity: 0;
  animation-name: rotating-text-1;
  // animation-delay: 0.5s;
  display: block !important;
}
.menu-collapsed :not(.expanded) .menu-item.text-truncate {
  display: none;
}
@keyframes rotating-text-1 {
  0% {
    // transform: translateY(200%);
  }

  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.event-list-buttons {
  margin-top: 8px !important;
  // padding-top: 20px !important;
}
.event-list-buttons li {
  background: transparent;
  padding: 2px 4px;
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
  font-size: 0.7rem;
  cursor: pointer;
}
.th-w-200 {
  width: 100px !important;
}
.show-only-label {
  font-size: 13px;
}
.textarea-font-smaller {
  font-size: 12px;
}
.add-photo {
  object-fit: contain;
}
.report-shopping-title {
  font-weight: 600;
  text-decoration: underline;
  color: var(--bs-primary);
  font-size: 20px;
}
.shopping-items-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--bs-primary);
  margin-left: 10px;
}
.shopping-sub-items {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  color: var(--bs-dark);
}
.shopping-list-items-wrapper {
  margin-bottom: 1rem;
}
// .shopping-list-items-wrapper:last-child {
//   margin-bottom: 0rem;
// }
.shopping-main-title {
  border-bottom: 1px solid;
  margin-bottom: 2rem;
  padding-bottom: 12px;
}
.shopping-main-title h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--bs-primary);
  margin-bottom: 0rem;
}
.shppoping-list-main-wrapper {
  margin-bottom: 2rem;
  border-bottom: 1px solid;
}
.shppoping-list-main-wrapper:last-child {
  margin-bottom: 2rem;
  border-bottom: unset;
}
.shopping-list-inner-items-wrapper {
  border-bottom: 1px solid;
  margin-bottom: 1rem;
}
.shopping-list-inner-items-wrapper:last-child {
  border-bottom: unset;
  margin-bottom: 1rem;
}
.recipe-instruction-wrapper {
  max-width: 800px;
  margin: 2rem auto;
  border: 1px solid;
  width: 100%;
}
.expiry-date {
  font-size: 12px;
}
.expiry-date p {
  font-weight: 600;
}
.freezer-label {
  font-weight: 600;
}
.confirm-email {
  font-weight: 800 !important;
  font-size: 18px;
  color: var(--bs-primary);
}
.auth-inner.confirm-email-wrapper {
  max-width: 350px !important;
  margin: auto;
  width: 100%;
}
.btn-closer-setting {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (max-width: 767.5px) {
  .btn-closer-setting {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}
.form-check-notifi {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}
input[type="radio"] {
  min-width: 1.4rem;
  width: 1.4rem;
  min-height: 1.4rem;
  height: 1.4rem;
}
.checkbox-setting {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.text-start-label {
  text-align: start;
}
.nav-tabs-inverse {
  border-bottom-width: 1px;
  .bg-primary {
    background-color: #eae5dd !important;
  }
  .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-bottom: -1px;
    color: #072f3d !important;
    margin-right: 5px;
    &.active.active {
      background-color: #072f3d !important;
      color: #fff !important;
      &:hover {
        color: #fff !important;
      }
    }
    &::after {
      display: none;
    }
    &:hover {
      color: #072f3d !important;
    }
  }
}
.nav-tabs {
  border-bottom-width: 1px;
  .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-bottom: -1px;
    color: #ffffff;
    margin-right: 5px;
    &.active {
      background-color: #ffffff !important;
      color: #072f3d;
      &:hover {
        color: #072f3d;
      }
    }
    &::after {
      display: none;
    }
    &:hover {
      color: #ffffff;
    }
  }
}
